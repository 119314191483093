<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-alert
        :value="month !== monthOf"
        color="info"
        icon="info"
      >
        Previous month AI calculations do not reflect the actual monthly AI that has been disbursed
        because subscribers may have changed their mobile plan from the original activation plan.
      </v-alert>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          solo-inverted
          clearable
          box
          hide-details
        />
      </v-card-title>
      <v-card-title>
        <h3 class="primary--text">
          1st Activation Incentive
        </h3>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <template v-if="withB10AI">
            <td
              v-if="props.item.plan === 'B10'"
              class="text-xs-center"
            >
              <v-icon>mdi-check</v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
&nbsp;
            </td>
          </template>
          <td
            v-if="props.item.plan === 'F10'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B39' || props.item.plan === 'B35'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B59'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B89'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B50'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B55'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B55Device'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B85'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B85Device'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td
              v-if="withB10AI"
              class="text-xs-center primary--text"
            >
              {{ B10.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ F10.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B39.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B59.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B89.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B49.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B55.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B55Device.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B85.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B85Device.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              1st AI:
            </td>
            <td
              v-if="withB10AI"
              class="text-xs-center primary--text"
            >
              {{ getRm(Number(B10.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(F10.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B39.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B59.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B89.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B49.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B55.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B55Device.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B85.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B85Device.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right">
              Total 1st AI:
            </td>
            <td
              :colspan="withB10AI ? (withB49 ? 11 : 10) : (withB49 ? 10 : 9)"
              class="text-xs-center primary"
            >
              {{ getRm(Number(B10.incentive) + Number(F10.incentive) + Number(B39.incentive) + Number(B49.incentive) + Number(B59.incentive) + Number(B89.incentive)+ Number(B55.incentive) + Number(B55Device.incentive) + Number(B85.incentive) + Number(B85Device.incentive) ) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-card-title>
        <h3 class="primary--text">
          2nd Activation Incentive
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers2"
        :items="items2"
        :loading="loading"
        :pagination.sync="pagination2"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <template v-if="withB10AI || B10_2.count">
            <td
              v-if="props.item.plan === 'B10'"
              class="text-xs-center"
            >
              <v-icon>mdi-check</v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
&nbsp;
            </td>
          </template>
          <td
            v-if="props.item.plan === 'F10'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B39' || props.item.plan === 'B35'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B59'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B89'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B50'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B55'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B55Device'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B85'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B85Device'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td
              v-if="withB10AI || B10_2.count"
              class="text-xs-center primary--text"
            >
              {{ B10_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ F10_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B39_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B59_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B89_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B49_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B55_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B55Device_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B85_2.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B85Device_2.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              2nd AI:
            </td>
            <td
              v-if="withB10AI || B10_2.count"
              class="text-xs-center primary--text"
            >
              {{ getRm(Number(B10_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(F10_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B39_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B59_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B89_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B49_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B55_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B55Device_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B85_2.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B85Device_2.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right">
              Total 2nd AI:
            </td>
            <td
              :colspan="withB10AI ? (withB49 ? 11 : 10) : (withB49 ? 10 : 9)"
              class="primary text-xs-center"
            >
              {{ getRm(Number(B10_2.incentive) + Number(F10_2.incentive) + Number(B39_2.incentive) + Number(B49_2.incentive) + Number(B59_2.incentive) + Number(B89_2.incentive) + Number(B150_2.incentive)+ Number(B55_2.incentive) + Number(B55Device_2.incentive) + Number(B85_2.incentive) + Number(B85Device_2.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-card-title>
        <h3 class="primary--text">
          3rd Activation Incentive
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers3"
        :items="items3"
        :loading="loading"
        :pagination.sync="pagination3"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <template v-if="withB10AI || B10_3.count">
            <td
              v-if="props.item.plan === 'B10'"
              class="text-xs-center"
            >
              <v-icon>mdi-check</v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
&nbsp;
            </td>
          </template>
          <td
            v-if="props.item.plan === 'F10'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B39' || props.item.plan === 'B35'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B59'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B89'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B50'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B55'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B55Device'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B85'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B85Device'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td
              v-if="withB10AI || B10_3.count"
              class="text-xs-center primary--text"
            >
              {{ B10_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ F10_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B39_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B59_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B89_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B49_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B55_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B55Device_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B85_3.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B85Device_3.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              3rd AI:
            </td>
            <td
              v-if="withB10AI || B10_3.count"
              class="text-xs-center primary--text"
            >
              {{ getRm(Number(B10_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(F10_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B39_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B59_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B89_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B49_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B55_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B55Device_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B85_3.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B85Device_3.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right">
              Total 3rd AI:
            </td>
            <td
              :colspan="withB10AI ? (withB49 ? 11 : 10) : (withB49 ? 10 : 9)"
              class="primary text-xs-center"
            >
              {{ getRm(Number(B10_3.incentive) + Number(F10_3.incentive) + Number(B39_3.incentive) + Number(B49_3.incentive) + Number(B59_3.incentive) + Number(B89_3.incentive) + Number(B150_3.incentive)+ Number(B55_3.incentive) + Number(B55Device_3.incentive) + Number(B85_3.incentive) + Number(B85Device_3.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-alert
        :value="true"
        color="info"
        icon="info"
      >
        Only ONEXOX Black More Campaign is eligible from fourth AI to sixth AI.
      </v-alert>
      <v-card-title>
        <h3 class="primary--text">
          4th Activation Incentive
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers4"
        :items="items4"
        :loading="loading"
        :pagination.sync="pagination4"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <template v-if="withB10AI || B10_4.count">
            <td
              v-if="props.item.plan === 'B10'"
              class="text-xs-center"
            >
              <v-icon>mdi-check</v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
&nbsp;
            </td>
          </template>
          <td
            v-if="props.item.plan === 'F10'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B39' || props.item.plan === 'B35'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B59'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B89'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B50'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td
              v-if="withB10AI || B10_4.count"
              class="text-xs-center primary--text"
            >
              {{ B10_4.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ F10_4.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B39_4.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B59_4.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B89_4.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B49_4.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              4th AI:
            </td>
            <td
              v-if="withB10AI || B10_4.count"
              class="text-xs-center primary--text"
            >
              {{ getRm(Number(B10_4.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(F10_4.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B39_4.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B59_4.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B89_4.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B49_4.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right">
              Total 4th AI:
            </td>
            <td
              :colspan="withB10AI ? (withB49 ? 11 : 10) : (withB49 ? 10 : 9)"
              class="primary text-xs-center"
            >
              {{ getRm(Number(B10_4.incentive) + Number(F10_4.incentive) + Number(B39_4.incentive) + Number(B49_4.incentive) + Number(B59_4.incentive) + Number(B89_4.incentive) + Number(B150_4.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-card-title>
        <h3 class="primary--text">
          5th Activation Incentive
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers5"
        :items="items5"
        :loading="loading"
        :pagination.sync="pagination5"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <template v-if="withB10AI || B10_4.count">
            <td
              v-if="props.item.plan === 'B10'"
              class="text-xs-center"
            >
              <v-icon>mdi-check</v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
&nbsp;
            </td>
          </template>
          <td
            v-if="props.item.plan === 'F10'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B39' || props.item.plan === 'B35'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B59'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B89'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B50'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td
              v-if="withB10AI || B10_5.count"
              class="text-xs-center primary--text"
            >
              {{ B10_5.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ F10_5.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B39_5.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B59_5.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B89_5.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B49_5.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              5th AI:
            </td>
            <td
              v-if="withB10AI || B10_5.count"
              class="text-xs-center primary--text"
            >
              {{ getRm(Number(B10_5.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(F10_5.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B39_5.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B59_5.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B89_5.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B49_5.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right">
              Total 5th AI:
            </td>
            <td
              :colspan="withB10AI ? (withB49 ? 11 : 10) : (withB49 ? 10 : 9)"
              class="primary text-xs-center"
            >
              {{ getRm(Number(B10_5.incentive) + Number(F10_5.incentive) + Number(B39_5.incentive) + Number(B49_5.incentive) + Number(B59_5.incentive) + Number(B89_5.incentive) + Number(B150_5.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-card-title>
        <h3 class="primary--text">
          6th Activation Incentive
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers6"
        :items="items6"
        :loading="loading"
        :pagination.sync="pagination6"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <template v-if="withB10AI || B10_6.count">
            <td
              v-if="props.item.plan === 'B10'"
              class="text-xs-center"
            >
              <v-icon>mdi-check</v-icon>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
&nbsp;
            </td>
          </template>
          <td
            v-if="props.item.plan === 'F10'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B39' || props.item.plan === 'B35'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B59'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B89'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
          <td
            v-if="props.item.plan === 'B50'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
&nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td
              v-if="withB10AI || B10_6.count"
              class="text-xs-center primary--text"
            >
              {{ B10_6.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ F10_6.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B39_6.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B59_6.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B89_6.count }}
            </td>
            <td class="text-xs-center primary--text">
              {{ B49_6.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right primary--text">
              6th AI:
            </td>
            <td
              v-if="withB10AI || B10_6.count"
              class="text-xs-center primary--text"
            >
              {{ getRm(Number(B10_6.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(F10_6.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B39_6.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B59_6.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B89_6.incentive)) }}
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(B49_6.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
&nbsp;
            </td>
            <td class="text-xs-right">
              Total 6th AI:
            </td>
            <td
              :colspan="withB10AI ? (withB49 ? 11 : 10) : (withB49 ? 10 : 9)"
              class="primary text-xs-center"
            >
              {{ getRm(Number(B10_6.incentive) + Number(F10_6.incentive) + Number(B39_6.incentive) + Number(B49_6.incentive) + Number(B59_6.incentive) + Number(B89_6.incentive) + Number(B150_6.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import { getRm } from '@/utils'

const reportTitle = 'Black AI'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        // const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local().setZone('Asia/Kuala_Lumpur').plus({ months: -3 })

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      blank: { count: 0, incentive: 0 },
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      expand: true,
      getRm: getRm,
      items: [],
      items2: [],
      items3: [],
      items4: [],
      items5: [],
      items6: [],
      counter: [],
      counter2: [],
      counter3: [],
      counter4: [],
      counter5: [],
      counter6: [],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'name',
      },
      pagination2: {
        descending: true,
        sortBy: 'name',
      },
      pagination3: {
        descending: true,
        sortBy: 'name',
      },
      pagination4: {
        descending: true,
        sortBy: 'name',
      },
      pagination5: {
        descending: true,
        sortBy: 'name',
      },
      pagination6: {
        descending: true,
        sortBy: 'name',
      },
      search: '',
      title: reportTitle,
      totalIn: 0,
      totalOut: 0,
    }
  },
  computed: {
    withB49: function () {
      return this.monthOf >= '2021-05'
    },
    headers: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      this.withB10AI && headers.push({
        align: 'center',
        sortable: false,
        text: 'B10',
        value: 'b10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'F10',
        value: 'f10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39/F39/M35',
        value: 'b39',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b140',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B55',
        value: 'b55',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B55Device',
        value: 'b55',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B85',
        value: 'b85',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B85Device',
        value: 'b85',
      })

      return headers
    },
    headers2: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      if (this.withB10AI || this.B10_2.count) {
        headers.push({
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        })
      }
      headers.push({
        align: 'center',
        sortable: false,
        text: 'F10',
        value: 'f10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39/F39/M35',
        value: 'b39',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b150',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B55',
        value: 'b55',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B55Device',
        value: 'b55Device',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B85',
        value: 'b85',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B85Device',
        value: 'b85Device',
      })

      return headers
    },
    headers3: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      if (this.withB10AI || this.B10_3.count) {
        headers.push({
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        })
      }
      headers.push({
        align: 'center',
        sortable: false,
        text: 'F10',
        value: 'f10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39/F39/M35',
        value: 'b39',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b150',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B55',
        value: 'b55',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B55Device',
        value: 'b55Device',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B85',
        value: 'b85',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B85Device',
        value: 'b85Device',
      })

      return headers
    },
    headers4: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      if (this.withB10AI || this.B10_3.count) {
        headers.push({
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        })
      }
      headers.push({
        align: 'center',
        sortable: false,
        text: 'F10',
        value: 'f10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39/F39/M35',
        value: 'b39',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b150',
      })

      return headers
    },
    headers5: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      if (this.withB10AI || this.B10_3.count) {
        headers.push({
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        })
      }
      headers.push({
        align: 'center',
        sortable: false,
        text: 'F10',
        value: 'f10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39/F39/M35',
        value: 'b39',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b150',
      })

      return headers
    },
    headers6: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      if (this.withB10AI || this.B10_3.count) {
        headers.push({
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        })
      }
      headers.push({
        align: 'center',
        sortable: false,
        text: 'F10',
        value: 'f10',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39/F39/M35',
        value: 'b39',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b150',
      })

      return headers
    },
    withB10AI: function () {
      return this.monthOf <= '2019-07'
    },
    withCategory: function () {
      return this.monthOf >= '2020-01'
    },
    B10: function () {
      return this.counter.B10 ? this.counter.B10 : this.blank
    },
    F10: function () {
      return this.counter.F10 ? this.counter.F10 : this.blank
    },
    B39: function () {
      return this.counter.B39 ? this.counter.B39 : this.blank
    },
    B49: function () {
      return this.counter.B49 ? this.counter.B49 : this.blank
    },
    B59: function () {
      return this.counter.B59 ? this.counter.B59 : this.blank
    },
    B89: function () {
      return this.counter.B89 ? this.counter.B89 : this.blank
    },
    B150: function () {
      return this.counter.B150 ? this.counter.B150 : this.blank
    },
    B55: function () {
      return this.counter.B55 ? this.counter.B55 : this.blank
    },
    B55Device: function () {
      return this.counter.B55Device ? this.counter.B55Device : this.blank
    },
    B85: function () {
      return this.counter.B85 ? this.counter.B85 : this.blank
    },
    B85Device: function () {
      return this.counter.B85Device ? this.counter.B85Device : this.blank
    },
    B10_2: function () {
      return this.counter2.B10 ? this.counter2.B10 : this.blank
    },
    F10_2: function () {
      return this.counter2.F10 ? this.counter2.F10 : this.blank
    },
    B39_2: function () {
      return this.counter2.B39 ? this.counter2.B39 : this.blank
    },
    B49_2: function () {
      return this.counter2.B49 ? this.counter2.B49 : this.blank
    },
    B59_2: function () {
      return this.counter2.B59 ? this.counter2.B59 : this.blank
    },
    B89_2: function () {
      return this.counter2.B89 ? this.counter2.B89 : this.blank
    },
    B150_2: function () {
      return this.counter2.B150 ? this.counter2.B150 : this.blank
    },
    B55_2: function () {
      return this.counter2.B55 ? this.counter2.B55 : this.blank
    },
    B55Device_2: function () {
      return this.counter2.B55 ? this.counter2.B55 : this.blank
    },
    B85_2: function () {
      return this.counter2.B85 ? this.counter2.B85 : this.blank
    },
    B85Device_2: function () {
      return this.counter2.B85Device ? this.counter2.B85Device : this.blank
    },
    B10_3: function () {
      return this.counter3.B10 ? this.counter3.B10 : this.blank
    },
    F10_3: function () {
      return this.counter3.F10 ? this.counter3.F10 : this.blank
    },
    B39_3: function () {
      return this.counter3.B39 ? this.counter3.B39 : this.blank
    },
    B49_3: function () {
      return this.counter3.B49 ? this.counter3.B49 : this.blank
    },
    B59_3: function () {
      return this.counter3.B59 ? this.counter3.B59 : this.blank
    },
    B89_3: function () {
      return this.counter3.B89 ? this.counter3.B89 : this.blank
    },
    B150_3: function () {
      return this.counter3.B150 ? this.counter3.B150 : this.blank
    },
    B55_3: function () {
      return this.counter3.B55 ? this.counter3.B55 : this.blank
    },
    B55Device_3: function () {
      return this.counter3.B55 ? this.counter3.B55 : this.blank
    },
    B85_3: function () {
      return this.counter3.B85 ? this.counter3.B85 : this.blank
    },
    B85Device_3: function () {
      return this.counter3.B85Device ? this.counter3.B85Device : this.blank
    },
    B10_4: function () {
      return this.counter4.B10 ? this.counter4.B10 : this.blank
    },
    F10_4: function () {
      return this.counter4.F10 ? this.counter4.F10 : this.blank
    },
    B39_4: function () {
      return this.counter4.B39 ? this.counter4.B39 : this.blank
    },
    B49_4: function () {
      return this.counter4.B49 ? this.counter4.B49 : this.blank
    },
    B59_4: function () {
      return this.counter4.B59 ? this.counter4.B59 : this.blank
    },
    B89_4: function () {
      return this.counter4.B89 ? this.counter4.B89 : this.blank
    },
    B150_4: function () {
      return this.counter4.B150 ? this.counter5.B150 : this.blank
    },
    B10_5: function () {
      return this.counter5.B10 ? this.counter5.B10 : this.blank
    },
    F10_5: function () {
      return this.counter5.F10 ? this.counter5.F10 : this.blank
    },
    B39_5: function () {
      return this.counter5.B39 ? this.counter5.B39 : this.blank
    },
    B49_5: function () {
      return this.counter5.B49 ? this.counter5.B49 : this.blank
    },
    B59_5: function () {
      return this.counter5.B59 ? this.counter5.B59 : this.blank
    },
    B89_5: function () {
      return this.counter5.B89 ? this.counter5.B89 : this.blank
    },
    B150_5: function () {
      return this.counter4.B150 ? this.counter5.B150 : this.blank
    },
    B10_6: function () {
      return this.counter6.B10 ? this.counter6.B10 : this.blank
    },
    F10_6: function () {
      return this.counter6.F10 ? this.counter6.F10 : this.blank
    },
    B39_6: function () {
      return this.counter6.B39 ? this.counter6.B39 : this.blank
    },
    B49_6: function () {
      return this.counter6.B49 ? this.counter6.B49 : this.blank
    },
    B59_6: function () {
      return this.counter6.B59 ? this.counter6.B59 : this.blank
    },
    B89_6: function () {
      return this.counter6.B89 ? this.counter6.B89 : this.blank
    },
    B150_6: function () {
      return this.counter6.B150 ? this.counter6.B150 : this.blank
    },
  },
  watch: {
    monthOf: function (val) {
      this.getBlackActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getBlackActivations (month) {
      const params = createGetParams({
        month: month,
      })
      this.loading = true
      this.items = this.items2 = this.items3 = this.items4 = this.items5 = this.items6 = []
      this.counter = this.counter2 = this.counter3 = this.counter4 = this.counter5 = this.counter6 = []
      this.$rest.get('getBlackActivations.php', params)
        .then(function (response) {
          if (response.data.items) this.items = response.data.items
          if (response.data.counter) this.counter = response.data.counter
          if (response.data.items2) this.items2 = response.data.items2
          if (response.data.counter2) this.counter2 = response.data.counter2
          if (response.data.items3) this.items3 = response.data.items3
          if (response.data.counter3) this.counter3 = response.data.counter3
          if (response.data.items4) this.items4 = response.data.items4
          if (response.data.counter4) this.counter4 = response.data.counter4
          if (response.data.items5) this.items5 = response.data.items5
          if (response.data.counter5) this.counter5 = response.data.counter5
          if (response.data.items6) this.items6 = response.data.items6
          if (response.data.counter6) this.counter6 = response.data.counter6
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
